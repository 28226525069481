<template>
    <main id="cuerpo">
    
    <Titular :titular_cabecero="titular"></Titular>

    <div class="container mt-5 mb-5">
        <div class="row">
            <div class="col-12 text-end">
                <router-link to="/mapa-fosas" class="btn btn-secondary">Volver al mapa</router-link>
            </div>
        </div>
    </div>

    <div class="container mb-5">
        <div class="row">
            <div class="col-sm-7">              
              <!-- <img v-bind:src="'data:image/jpeg;base64,'+concejo.FOTO" class="img-fluid" alt="{{concejo.TITULAR}}">  -->

              <vue-image-zoomer 
                  v-bind:regular="'data:image/jpeg;base64,'+datos_concejo.FOTO"                   
                  :zoom-amount="3" 
                  :click-zoom="true"
                  click-message="Haga clic para hacer zoom"        
                  img-class="img-fluid"
                  alt="{{datos_concejo.TITULAR}}">
                  <img v-bind:src="'data:image/jpeg;base64,'+datos_concejo.FOTO" class="img-fluid" alt="{{datos_concejo.TITULAR}}">               
              </vue-image-zoomer>              
            </div>

            <div class="col-sm-5 course-details">
              <div class="course-info d-flex justify-content-between align-items-center">
                <h5>Nº de fosas</h5>
                <p>{{datos_concejo.NUM_FOSAS}}</p>
              </div>

              <div>
                <ul>
                  <li v-for="concejo in datos_concejo.CONCEJOS" v-bind:key="concejo.NAME">
                    {{ concejo.NAME }} (Nº de fosas: {{ concejo.NUM_FOSAS }})
                    <ul v-if="(concejo.FOSAS).length>0">
                      <li v-for="data_fosa in concejo.FOSAS" :key="data_fosa.ID"> 
                        <router-link :to="`/fosa/${datos_concejo.LINK}/${data_fosa.LINK}`">
                          <span v-if="(data_fosa.NAME).length>0">{{ data_fosa.NAME }} ({{ data_fosa.NUM_FOSA }})</span>
                          <span v-else>{{ data_fosa.TIP_FOSA }} ({{ data_fosa.NUM_FOSA }})</span>
                        </router-link>                        
                      </li>
                    </ul> 
                  </li>
                </ul>
              </div>
            </div>
        </div>
    </div>
    
  </main><!-- fin #cuerpo --> 
</template>

<script>

import Titular from "../components/Titular.vue";
import { VueImageZoomer } from 'vue-image-zoomer'
import {getFichaAgrupacionConcejos} from "../helpers/getConcejos.ts"

import 'vue-image-zoomer/dist/style.css';

export default {
  props:{
    id:{
        type: String,
        required: true
    }
  },
  data() {
    return {
      titular: '',
      datos_concejo: []
    }
  },
  components: {
      Titular,
      VueImageZoomer
  },
  methods:{                
    async getDatosConcejo(){      
      this.datos_concejo = await getFichaAgrupacionConcejos(this.id)      
      console.log(this.datos_concejo.CONCEJOS);
      this.titular = this.datos_concejo.TITULAR;
    }
  },
  created(){        
      this.getDatosConcejo()
  }
}
</script>