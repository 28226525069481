<template>
    <main id="cuerpo">
    
        <Titular :titular_cabecero="titular"></Titular>

        <div class="container mt-5">
            <div class="row">
                <div class="col-12 text-end">
                    <router-link :to="`/concejo/${datos_concejo.LINK}`" class="btn btn-secondary">Volver atrás</router-link>
                </div>
            </div>
        </div>

        <div class="container mb-5">
            <div class="row">
                <section id="course-details" class="course-details">
                    <div class="container aos-init aos-animate" data-aos="fade-up">

                        <div class="row">
                            <div class="col-lg-8">
                                                                
                                <h3 v-if="(datos_fosa.NAME).length>0">{{ datos_fosa.NAME }}</h3>
                                <h3 v-else>{{ datos_fosa.TIP_FOSA }}</h3>

                                <p class="fw-bold">Localización</p>
                                <p>{{ datos_fosa.LOC }}</p>

                                <p class="fw-bold">Tipo de fosa</p>
                                <p>{{ datos_fosa.TIP_FOSA }}</p>

                                <p class="fw-bold">Intervenciones realizadas</p>
                                <p>{{ datos_fosa.INT_REA }}</p>

                                <p class="fw-bold">Nº de cadáveres</p>
                                <p>{{ datos_fosa.NUM_CAD }}</p>

                                <p class="fw-bold">Identificados</p>
                                <p>{{ datos_fosa.IDENT }}</p>

                                <p class="fw-bold">Fuentes</p>
                                <p>{{ datos_fosa.FUENTES }}</p>
                                
                            </div>
                            <div class="col-lg-4">
                                
                                <p>Para mas información, puede descargar los datos completos de la fosa en el siguiente enlace:</p>
                                <a :href="link_pdf_fosa" target="_blank" class="btn btn-link-mapa">Descargar fosa en PDF</a>
                                                               
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    </main><!-- fin #cuerpo --> 

</template>

<script>

import Titular from "../components/Titular.vue";

import {getFichaAgrupacionConcejos} from "../helpers/getConcejos.ts"

export default {
  props:{
    concejo:{
        type: String,
        required: true
    },
    id:{
        type: String,
        required: true
    }
  },
  data() {
    return {
      titular: '',
      datos_fosa: [],
      datos_concejo: [],
      link_pdf_fosa:''
    }
  },
  components: {
      Titular
  },
  methods:{                
    async getDatosConcejoYdeFosa(){              
      this.datos_concejo = await getFichaAgrupacionConcejos(this.concejo)            
      this.setDatosFosa(this.datos_concejo.CONCEJOS);      
      this.titular = this.datos_fosa.CONCEJO;      
    },
    setDatosFosa(concejos){        
        concejos.forEach((concejo, index) => { 
            (concejo.FOSAS).forEach((fosa, index) => { 
                if(fosa.LINK===this.id){
                    this.datos_fosa = fosa;
                    this.link_pdf_fosa = this.baseurl+'/web/data/pdf/'+(fosa.PDF).split("-")[0]+'/'+fosa.PDF;                    
                }
            })      
        })        
    }
  },
  created(){        
      this.getDatosConcejoYdeFosa()
  }
}
</script>